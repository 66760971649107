<template>
  <!-- When timeout is set to 0 (snackbar will not automatically hide), we change the position to bottom for mobile.
    Like that, the snackbar is not covering the navigation and the user may navigate away from the current page.-->
  <v-snackbar
    :value="value"
    :top="timeout !== 0 || (timeout === 0 && !$vuetify.breakpoint.xsOnly)"
    :bottom="timeout === 0 && !!$vuetify.breakpoint.xsOnly"
    :color="color"
    :timeout="timeout"
    :vertical="$vuetify.breakpoint.xsOnly"
    class="snackbar"
    data-cy="snackbar"
    @input="setSnackbarVisibility"
  >
    {{ message }}
    <template #action="{ attrs }">
      <!-- Button with text and link or route -->
      <v-btn
        v-if="isTextAction"
        text
        :href="actionHref"
        :nuxt="!!action.to"
        :to="action.to"
        :target="action.target ? action.target : ''"
        color="white"
        v-bind="attrs"
      >
        {{ actionText }}
      </v-btn>

      <!-- Button with text and custom function on click -->
      <v-btn
        v-else-if="isFunctionAction"
        text
        color="white"
        v-bind="attrs"
        data-cy="buttonSnackbarCustomFunction"
        @click="action.function"
      >
        {{ actionText }}
      </v-btn>

      <!-- Standard snackbar with close button to hide snackbar -->
      <v-btn
        v-else-if="!action.hideCloseButton"
        fab
        text
        height="24"
        width="24"
        data-cy="buttonHideSnackbar"
        @click="setSnackbarVisibility(false)"
      >
        <v-icon>$vuetify.icons.highlight_off</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue'
import type { TranslateResult } from 'vue-i18n'

type D = Record<string, string | undefined>

export default Vue.extend({
  computed: {
    message(): string | TranslateResult {
      const msg = (this.$store.state as RootState).snackbar.message
      if (!msg || typeof msg === 'string') return msg || ''
      if (Array.isArray(msg)) {
        return msg.map(item => this.createString(item)).join(' ')
      } else {
        return this.createString(msg)
      }
    },
    actionText(): string | TranslateResult {
      const msg = this.action.text
      if (!msg || typeof msg === 'string') return msg || ''
      return this.createString(msg)
    },
    actionHref(): string | TranslateResult {
      const msg = this.action.href
      if (!msg || typeof msg === 'string') return msg || ''
      return this.createString(msg)
    },
    value(): boolean {
      return (this.$store.state as RootState).snackbar.value
    },
    timeout(): number | undefined {
      return (this.$store.state as RootState).snackbar.timeout
    },
    color(): string {
      return (this.$store.state as RootState).snackbar.color
    },
    action(): SnackBarAction {
      return (this.$store.state as RootState).snackbar.action
    },
    isTextAction(): boolean {
      return 'text' in this.action && !this.action.function
    },
    isFunctionAction(): boolean {
      return 'text' in this.action && typeof this.action.function === 'function'
    },
  },
  methods: {
    translations(key: string, data?: D, count?: number): TranslateResult {
      return {
        'profile.visual_signature.snackbar.signature_deleted': () =>
          this.$t('profile.visual_signature.snackbar.signature_deleted'),
        'profile.visual_signature.snackbar.signature_created': () =>
          this.$t('profile.visual_signature.snackbar.signature_created'),
        'add_signer.dialog.feedback.success': (data?: D) => this.$t('add_signer.dialog.feedback.success', data),
        'delegate.dialog.feedback.success': (data?: D, count?: number) =>
          this.$tc('delegate.dialog.feedback.success', count, data),
        'global.general_error': () => this.$t('global.general_error'),
        'global.decline.document_declined': () => this.$t('global.decline.document_declined'),
        'global.delete.document_deleted': () => this.$t('global.delete.document_deleted'),
        'dialog.withdraw_document.snackbar.document_withdrawn': () =>
          this.$t('dialog.withdraw_document.snackbar.document_withdrawn'),
        'global.copied_to_clipboard': () => this.$t('global.copied_to_clipboard'),
        'upload.error.file_type_error': (data?: D) => this.$t('upload.error.file_type_error', data),
        'upload.error.file_size_error': (data?: D) => this.$t('upload.error.file_size_error', data),
        'upload.error.total_files_size_error': (data?: D) => this.$t('upload.error.total_files_size_error', data),
        'upload.error.limit_error': (data?: D) => this.$t('upload.error.limit_error', data),
        'upload.error.already_uploaded': () => this.$t('upload.error.already_uploaded'),
        'upload.error.pdf_damaged.snackbar': () => this.$t('upload.error.pdf_damaged.snackbar'),
        'upload.error.pdf_invalid_signatures.snackbar': () => this.$t('upload.error.pdf_invalid_signatures.snackbar'),
        'upload.error.pdf_protected_by_author.snackbar': () => this.$t('upload.error.pdf_protected_by_author.snackbar'),
        'upload.error.pdf_pwd_protected.snackbar': () => this.$t('upload.error.pdf_pwd_protected.snackbar'),
        'upload.error.pdf_protected.snackbar': () => this.$t('upload.error.pdf_protected.snackbar'),
        'upload.error.pdf_invalid.snackbar': () => this.$t('upload.error.pdf_invalid.snackbar'),
        'upload.error.malware_detected.snackbar': (data?: D) => this.$t('upload.error.malware_detected.snackbar', data),
        'upload.error.attachment_unsupported.snackbar': (data?: D) =>
          this.$t('upload.error.attachment_unsupported.snackbar', data),
        'global.profile.email_verification_alert.cta': () => this.$t('global.profile.email_verification_alert.cta'),
        'global.profile.email_verification_alert.text': () => this.$t('global.profile.email_verification_alert.text'),
        'signature_request.errors.no_signing_credit_new': () =>
          this.$t('signature_request.errors.no_signing_credit_new'),
        'snackbar.payment_method_added.during_trialing': () => this.$t('snackbar.payment_method_added.during_trialing'),
        'billing.settings.cc.save.saved': () => this.$t('billing.settings.cc.save.saved'),
        'global.error.generic_support': () => this.$t('global.error.generic_support'),
        'snackbar.feedback.thank_you': () => this.$t('snackbar.feedback.thank_you'),
        'viewer.eid_data.snackbar.aes.message.failure': () => this.$t('viewer.eid_data.snackbar.aes.message.failure'),
        'viewer.eid_data.snackbar.aes.message.success': () => this.$t('viewer.eid_data.snackbar.aes.message.success'),
        'viewer.tan.invalid.feedback.success': () => this.$t('viewer.tan.invalid.feedback.success'),
        'viewer.visual_signature.snackbar.message': () => this.$t('viewer.visual_signature.snackbar.message'),
        'overlay.mobile_aes.snackbar.wrong_code_message': () =>
          this.$t('overlay.mobile_aes.snackbar.wrong_code_message'),
        'overlay.mobile_aes.snackbar.resend_code_message': () =>
          this.$t('overlay.mobile_aes.snackbar.resend_code_message'),
        'overlay.mobile_aes.snackbar.new_code_sent_message': () =>
          this.$t('overlay.mobile_aes.snackbar.new_code_sent_message'),
        'overlay.part11_verification.snackbar.new_code_sent_message': () =>
          this.$t('overlay.part11_verification.snackbar.new_code_sent_message'),
        'viewer.tan.expired.link_flow.feedback.success': () => this.$t('viewer.tan.expired.link_flow.feedback.success'),
        'viewer.tan.expired.tan_flow.feedback.success': () => this.$t('viewer.tan.expired.tan_flow.feedback.success'),
        'business.auto_deletion.snackbar_activated': (_?: D, count?: number) =>
          this.$tc('business.auto_deletion.snackbar_activated', count),
        'business.auto_deletion.snackbar_deactivated': () => this.$t('business.auto_deletion.snackbar_deactivated'),
        'business.auto_deletion.snackbar': (_?: D, count?: number) =>
          this.$tc('business.auto_deletion.snackbar', count),
        'signature_request.invite.error_not_empty_sr': () => this.$t('signature_request.invite.error_not_empty_sr'),
        'signup.mail_sent.mail_sent': () => this.$t('signup.mail_sent.mail_sent'),
        'billing.settings.cc.remove.removed': () => this.$t('billing.settings.cc.remove.removed'),
        'business.not_allowed': () => this.$t('business.not_allowed'),
        'signature_request.errors.no_data_match.direct_sign.qes.zertes.message_text': () =>
          this.$t('signature_request.errors.no_data_match.direct_sign.qes.zertes.message_text'),
        'signature_request.errors.no_data_match.direct_sign.qes.eidas.message_text': () =>
          this.$t('signature_request.errors.no_data_match.direct_sign.qes.eidas.message_text'),
        'login.logged_out': () => this.$t('login.logged_out'),
        'login.incorrect': (data?: D) => this.$t('login.incorrect', data),
        'login.incorrect_new': () => this.$t('login.incorrect_new'),
        'billing.settings.address.error': () => this.$t('billing.settings.address.error'),
        'business.billing_autoinvoicing.snackbar.subscription_cancellation_confirmation': () =>
          this.$t('business.billing_autoinvoicing.snackbar.subscription_cancellation_confirmation'),
        'business.billing_autoinvoicing.snackbar.subscription_reactivation_confirmation': () =>
          this.$t('business.billing_autoinvoicing.snackbar.subscription_reactivation_confirmation'),
        'snackbar.payment_method_added.message': () => this.$t('snackbar.payment_method_added.message'),
        'snackbar.payment_details_saved.message': (data?: D) => this.$t('snackbar.payment_details_saved.message', data),
        'billing.settings.cc.error._comment': () => this.$t('billing.settings.cc.error._comment'),
        'billing.settings.cc.error.card_declined': () => this.$t('billing.settings.cc.error.card_declined'),
        'billing.settings.cc.error.confirmation': () => this.$t('billing.settings.cc.error.confirmation'),
        'billing.settings.cc.error.expired_card': () => this.$t('billing.settings.cc.error.expired_card'),
        'billing.settings.cc.error.general': () => this.$t('billing.settings.cc.error.general'),
        'billing.settings.cc.error.incorrect_cvc': () => this.$t('billing.settings.cc.error.incorrect_cvc'),
        'billing.settings.cc.error.incorrect_number': () => this.$t('billing.settings.cc.error.incorrect_number'),
        'billing.settings.cc.error.incorrect_zip': () => this.$t('billing.settings.cc.error.incorrect_zip'),
        'billing.settings.cc.error.invalid_cvc': () => this.$t('billing.settings.cc.error.invalid_cvc'),
        'billing.settings.cc.error.invalid_expiry_month': () =>
          this.$t('billing.settings.cc.error.invalid_expiry_month'),
        'billing.settings.cc.error.invalid_expiry_year': () => this.$t('billing.settings.cc.error.invalid_expiry_year'),
        'billing.settings.cc.error.invalid_number': () => this.$t('billing.settings.cc.error.invalid_number'),
        'billing.settings.cc.error.processing_error': () => this.$t('billing.settings.cc.error.processing_error'),
        'billing.settings.cc.error.setup': () => this.$t('billing.settings.cc.error.setup'),
        'business.developers.api.create.error.limit_reached.demo': (data?: D) =>
          this.$t('business.developers.api.create.error.limit_reached.demo', data),
        'business.developers.api.create.error.limit_reached.production': (data?: D) =>
          this.$t('business.developers.api.create.error.limit_reached.production', data),
        'business.developers.api.delete.dialog.key_deleted': () =>
          this.$t('business.developers.api.delete.dialog.key_deleted'),
        'business.members.domains.add.errors.user_not_admin': () =>
          this.$t('business.members.domains.add.errors.user_not_admin'),
        'business.members.domains.add.errors.domain_invalid': () =>
          this.$t('business.members.domains.add.errors.domain_invalid'),
        'business.members.domains.add.errors.public_domain': () =>
          this.$t('business.members.domains.add.errors.public_domain'),
        'business.members.domains.add.errors.already_taken': () =>
          this.$t('business.members.domains.add.errors.already_taken'),
        'business.members.msgs.add_member_success': (data?: D, count?: number) =>
          this.$tc('business.members.msgs.add_member_success', count, data),
        'business.members.msgs.user_doesnt_exist': (data?: D) =>
          this.$t('business.members.msgs.user_doesnt_exist', data),
        'business.members.msgs.cannot_remove_yourself': () => this.$t('business.members.msgs.cannot_remove_yourself'),
        'business.members.msgs.last_admin': () => this.$t('business.members.msgs.last_admin'),
        'business.members.msgs.remove_member_success': (data?: D) =>
          this.$t('business.members.msgs.remove_member_success', data),
        'business.members.msgs.make_admin_success': (data?: D) =>
          this.$t('business.members.msgs.make_admin_success', data),
        'business.members.msgs.cannot_revoke_yourself': () => this.$t('business.members.msgs.cannot_revoke_yourself'),
        'business.members.msgs.revoke_admin_success': (data?: D) =>
          this.$t('business.members.msgs.revoke_admin_success', data),
        'business.members.msgs.reactivate_subscription_success': (data?: D) =>
          this.$t('business.members.msgs.reactivate_subscription_success', data),
        'business.members.msgs.create_subscription_success': (data?: D) =>
          this.$t('business.members.msgs.create_subscription_success', data),
        'business.members.msgs.cancel_subscription_success': (data?: D) =>
          this.$t('business.members.msgs.cancel_subscription_success', data),
        'business.auto_remind.saved': () => this.$t('business.auto_remind.saved'),
        'business.auto_remind.deactivated': () => this.$t('business.auto_remind.deactivated'),
        'business.auto_remind.activated': () => this.$t('business.auto_remind.activated'),
        'business.signup.step.1.snackbar.mail_confirmed': () =>
          this.$t('business.signup.step.1.snackbar.mail_confirmed'),
        'direct_sign.identification.aes.success': () => this.$t('direct_sign.identification.aes.success'),
        'direct_sign.identification.qes.success': () => this.$t('direct_sign.identification.qes.success'),
        'unlock_qes.eu.video_ident.start.snackbar_error': () =>
          this.$t('unlock_qes.eu.video_ident.start.snackbar_error'),
        'signature_request.invite.error_self_sign_quality': () =>
          this.$t('signature_request.invite.error_self_sign_quality'),
        'password_reset.new.successfully_reset': () => this.$t('password_reset.new.successfully_reset'),
        'personal_data.email_confirmation.success.notification': () =>
          this.$t('personal_data.email_confirmation.success.notification'),
        'personal_data.email_confirmation.error.resend_notification': () =>
          this.$t('personal_data.email_confirmation.error.resend_notification'),
        'personal_data.user_save_success': (data?: D) => this.$t('personal_data.user_save_success', data),
        'personal_data.user_save_error': () => this.$t('personal_data.user_save_error'),
        'global.password_incorrect': () => this.$t('global.password_incorrect'),
        'personal_data.password_save_error_invalid_old_pw': () =>
          this.$t('personal_data.password_save_error_invalid_old_pw'),
        'personal_data.password_save_error': () => this.$t('personal_data.password_save_error'),
        'personal_data.password_save_success': () => this.$t('personal_data.password_save_success'),
        'global.profile.aes_ready_notification.message': () => this.$t('global.profile.aes_ready_notification.message'),
        'overlay.mobile_aes.snackbar.code_sent_message': () => this.$t('overlay.mobile_aes.snackbar.code_sent_message'),
        'signature_request.errors.no_data_match.direct_sign.qes.zertes.cta_text': () =>
          this.$t('signature_request.errors.no_data_match.direct_sign.qes.zertes.cta_text'),
        'signature_request.errors.no_data_match.direct_sign.qes.zertes.cta_link': () =>
          this.$t('signature_request.errors.no_data_match.direct_sign.qes.zertes.cta_link'),
        'signature_request.errors.no_data_match.direct_sign.qes.eidas.cta_text': () =>
          this.$t('signature_request.errors.no_data_match.direct_sign.qes.eidas.cta_text'),
        'signature_request.errors.no_data_match.direct_sign.qes.eidas.cta_link': () =>
          this.$t('signature_request.errors.no_data_match.direct_sign.qes.eidas.cta_link'),
        'global.profile.aes_ready_notification.action.text': () =>
          this.$t('global.profile.aes_ready_notification.action.text'),
        'global.profile.cannot_sign_alert.link': () => this.$t('global.profile.cannot_sign_alert.link'),
        'overlay.mobile_aes.snackbar.resend_code_cta_text': () =>
          this.$t('overlay.mobile_aes.snackbar.resend_code_cta_text'),
        'global.profile.cannot_sign_alert.cta': () => this.$t('global.profile.cannot_sign_alert.cta'),
        'global.profile.cannot_sign_alert.text': () => this.$t('global.profile.cannot_sign_alert.text'),
        'business.billing.plan.add_seats.success': (data?: D) =>
          this.$t('business.billing.plan.add_seats.success', data),
        'business.billing.plan.restore_seats.success': (data?: D) =>
          this.$t('business.billing.plan.restore_seats.success', data),
        'business.developers.api.data.dialog.user_copied': () =>
          this.$t('business.developers.api.data.dialog.user_copied'),
        'business.developers.api.data.dialog.key_copied': () =>
          this.$t('business.developers.api.data.dialog.key_copied'),
        'signup.flows.eidplus.complete.snackbar.text': () => this.$t('signup.flows.eidplus.complete.snackbar.text'),
        'business.upgrade.message.upgrade_success': () => this.$t('business.upgrade.message.upgrade_success'),
        'business.upgrade.message.change_success': () => this.$t('business.upgrade.message.change_success'),
        'business.members.msgs.cant_make_admin': () => this.$t('business.members.msgs.cant_make_admin'),
        'business.members.msgs.user_not_verified_yet': () => this.$t('business.members.msgs.user_not_verified_yet'),
        'business.members.msgs.user_not_admin': () => this.$t('business.members.msgs.user_not_admin'),
        'business.members.msgs.user_needs_to_complete_signup': (data?: D) =>
          this.$t('business.members.msgs.user_needs_to_complete_signup', data),
        'business.members.msgs.user_already_member_other_business': (data?: D) =>
          this.$t('business.members.msgs.user_already_member_other_business', data),
        'business.members.msgs.user_could_not_be_added': (data?: D) =>
          this.$t('business.members.msgs.user_could_not_be_added', data),
        'business.members.msgs.user_already_invited': (data?: D) =>
          this.$t('business.members.msgs.user_already_invited', data),
        'global.name': () => this.$t('global.name'),
        'global.email': () => this.$t('global.email'),
        'business.settings.save_success': () => this.$t('business.settings.save_success'),
        'business.settings.error': () => this.$t('business.settings.error'),
        'ch_ident.auto.snackbar_error': () => this.$t('ch_ident.auto.snackbar_error'),
        'global.delete.document_deleted_plural': (_?: D, count?: number) =>
          this.$tc('global.delete.document_deleted_plural', count),
        'dialog.decline_document.snackbar.document_declined_and_deleted': () =>
          this.$t('dialog.decline_document.snackbar.document_declined_and_deleted'),
        'dialog.withdraw_document.snackbar.document_withdrawn_and_deleted': () =>
          this.$t('dialog.withdraw_document.snackbar.document_withdrawn_and_deleted'),
        'signature_request.invite.error_self_sign_quality_action.message': () =>
          this.$t('signature_request.invite.error_self_sign_quality_action.message'),
        'signature_request.invite.error_self_sign_quality_action.action.text': () =>
          this.$t('signature_request.invite.error_self_sign_quality_action.action.text'),
        'signature_request.invite.error_self_sign_quality_action.action.href': () =>
          this.$t('signature_request.invite.error_self_sign_quality_action.action.href'),
        'reminders.snackbar': () => this.$t('reminders.snackbar'),
        'remove_signer.signer_removed': () => this.$t('remove_signer.signer_removed'),
        'global.email_invalid': () => this.$t('global.email_invalid'),
      }[key](data, count)
    },
    createString(msg: SnackBarMessage): string | TranslateResult {
      const d: D | undefined = !msg.data
        ? undefined
        : Object.entries(msg.data).reduce((obj, [k, v]) => {
            if (!v) return obj
            obj[k] = typeof v === 'object' ? (this.translations(v?.key) as string) : String(v)
            return obj
          }, {} as D)
      const finalMessage = this.translations(msg.key, d, msg.count) as string
      return msg.append ? `${finalMessage}${msg.append}` : finalMessage
    },
    setSnackbarVisibility(visibility: boolean): void {
      if (!visibility) {
        void this.$store.dispatch('resetSnackbar', false)
      }
    },
  },
})
</script>

<style lang="sass">
.snackbar
  padding-top: 0 !important
  > div
    margin: 0
    > div
      font-weight: 700
</style>
